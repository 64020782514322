import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "antd";
import axios from "axios";
import { Server } from "App";
import moment from "moment";
import { loadStripe } from "@stripe/stripe-js";

const PracticeAssessment = () => {
  const navigate = useNavigate();
  const [practiceAssessment, setPracticeAssessment] = useState([]);
  const [practiceAssessmentCount, setPracticeAssessmentCount] = useState("");
  const [videoId, setVideoId] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getPracticeAssessment();
  }, []);
  const getPracticeAssessment = async () => {
    try {
      let userId = localStorage.getItem("UserId");

      const { data } = await axios.get(
        `${Server}/api/v1/practiceInterview/getInterview/${userId}`
      );
      setPracticeAssessment(data.practiceInterview);
      setLoading(true);
      setPracticeAssessmentCount(data.practiceInterviewCount);
      setVideoId(data.videoId);
    } catch (error) {
      console.log(error);
    }
  };
  const deleteVideo = async (Id) => {
    try {
      let userId = localStorage.getItem("UserId");

      const { data } = await axios.delete(
        `${Server}/api/v1/practiceInterview/delete/interview/${userId}/${videoId}`
      );
      setPracticeAssessment((prevData) =>
        prevData.filter((item) => item.videoId !== Id)
      );
      // getPracticeAssessment();
    } catch (error) {
      console.log(error);
    }
  };
  const handleNavigate = () => {
    navigate("/practicalAssessment"); // Update this route to your actual path
  };
  const subscribe = async () => {
    try {
      let userId = localStorage.getItem("UserId");
      let role = localStorage.getItem("Role");
      const demopriceid = "price_1QOlwvE6AeqDfCrWswoUmryE";
      // const demopriceid = "price_1QNHHPE6AeqDfCrWh3ieZBwI";
      const priceId = "price_1QOlMKE6AeqDfCrWAUIm3qYT";
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_API_KEY);
      const body = {
        plan: "Practice Interview",
        priceId: priceId,
        userId: userId,
        role: role,
      };
      const headers = {
        Content: "application/json",
      };
      const { data } = await axios.post(
        `${Server}/api/v1/subscribe/create/checkout-session`,
        body,
        {
          Headers: headers,
        }
      );
      console.log(data);
      // const session = await response.json();
      // localStorage.setItem("ses", data);
      const result = stripe.redirectToCheckout({
        sessionId: data.id,
      });
      // }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <section className="user-dashboard">
        <div className="dashboard-outer">
          <div className="upper-title-box">
            <h3>Practice Video Assessment</h3>
            <div className="text">Ready to jump back in?</div>
          </div>
          {loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "20px",
                marginBottom: "20px",
              }}
            >
              {" "}
              {practiceAssessmentCount === 0 ? (
                <button onClick={() => subscribe()} className="btn btn-primary">
                  Subscribe for $15 to avail 3 Interview
                </button>
              ) : (
                <button onClick={handleNavigate} className="btn btn-primary">
                  Practice Video Assessment
                </button>
              )}
            </div>
          ) : null}

          <div className="row">
            <div className="col-lg-12">
              <div className="ls-widget">
                <div className="tabs-box">
                  <div className="widget-title">
                    <h4>My Practice Video Assessment</h4>

                    <div className="chosen-outer">
                      <h4>
                        Practice Interview Count : {practiceAssessmentCount}
                      </h4>
                      {/*<select className="chosen-select">
                        <option>Last 6 Months</option>
                        <option>Last 12 Months</option>
                        <option>Last 16 Months</option>
                        <option>Last 24 Months</option>
                        <option>Last 5 Years</option>
                      </select>*/}
                    </div>
                  </div>

                  <div className="widget-content">
                    <div className="table-outer">
                      {practiceAssessment.length === 0 ? (
                        <p className="text-center">No Assessments</p>
                      ) : (
                        <table className="default-table manage-job-table">
                          <thead>
                            <tr>
                              <th>Job Title</th>
                              <th>Interview Date</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {practiceAssessment.map((e, subIdx) => (
                              <tr key={subIdx}>
                                <td>
                                  <h6>{e.jobTitle}</h6>
                                  {/*<span className="info">
                                    <i className="icon flaticon-map-locator"></i>{" "}
                                    {e.City}, {e.Country}
                                  </span>*/}
                                </td>
                                <td>{moment(e.Date).format("MMM Do YYYY")}</td>
                                <td>
                                  <div className="option-box">
                                    <ul className="option-list">
                                      <li>
                                        <a
                                          href={e.video}
                                          data-text="View Result"
                                          target="_blank"
                                        >
                                          <span className="la la-eye"></span>
                                        </a>
                                      </li>
                                      <li>
                                        <button
                                          data-text="View Result"
                                          onClick={() => deleteVideo(e.videoId)}
                                        >
                                          <span className="la la-trash"></span>
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="copyright-text">
        <p>© 2024 Skill Stream. All Right Reserved.</p>
      </div>
    </div>
  );
};

export default PracticeAssessment;
