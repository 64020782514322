import React, { useState } from "react";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { Server } from "../../App";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
const Googlelogin = ({ userType, setIsModalOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const clientId =
    "791901156890-cfquc9uvchhtq3ve65nmqh03vo5r7ulc.apps.googleusercontent.com";
  console.log(setIsModalOpen);
  const handleGoogleLogin = async (credentialResponse) => {
    try {
      let body = {
        credential: credentialResponse.credential,
        clientId: credentialResponse.clientId,
        role: userType,
      };
      const { data } = await axios.post(
        `${Server}/api/v1/auth/googleLogin`,
        body
      );
      console.log(data);
      if (data.message === "Login Successfully") {
        toast.success(data.message);
        if (data.role === "Candidate") {
          setTimeout(() => {
            if (location.pathname === "/" || location.pathname === "/contact") {
              navigate("/candidate-dashboard");
            } else {
              navigate(location.pathname);
              window.location.reload();
            }
            setIsModalOpen(false);
          }, 200);
        } else {
          setTimeout(() => {
            if (location.pathname === "/" || location.pathname === "/contact") {
              if (!data.subscribed) {
                navigate("/pricing");
              } else {
                navigate("/employer-dashboard");
              }
            } else {
              navigate(location.pathname);
              window.location.reload();
            }
            setIsModalOpen(false);
          }, 200);
          let Subscribed = localStorage.setItem("Subscribed", data.subscribed);
          let linkedInloggedIn = localStorage.setItem(
            "linkedinAccessToken",
            data.linkedInloggedIn
          );
        }
        let storeToken = localStorage.setItem("token", data.accessToken);
        let storeRole = localStorage.setItem("Role", data.role);
        let userId = localStorage.setItem("UserId", data.userId);
        let profileCompleted = localStorage.setItem(
          "ProfileCompleted",
          data.profileCompleted
        );
       
      } else if (data.message === "Registered Successfully") {
        if (data.role === "Candidate") {
          // setTimeout(() => {
          navigate("/candidate-dashboard");
          // }, 300);
        } else {
          // setTimeout(() => {
          if (!data.subscribed) {
            navigate("/pricing");
          } else {
            navigate("/employer-dashboard");
          }
          let linkedInloggedIn = localStorage.setItem(
            "linkedinAccessToken",
            data.linkedInloggedIn
          );
          let Subscribed = localStorage.setItem("Subscribed", data.subscribed);
          // }, 300);
        }
        let storeToken = localStorage.setItem("token", data.accessToken);
        let storeRole = localStorage.setItem("Role", data.role);
        let userId = localStorage.setItem("UserId", data.userId);
        let profileCompleted = localStorage.setItem(
          "ProfileCompleted",
          data.profileCompleted
        );
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Internal server error");
      }
    }
  };
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <Toaster position="top-center" reverseOrder={false} />
      <GoogleLogin
        onSuccess={(credentialResponse) => {
          console.log(credentialResponse);

          handleGoogleLogin(credentialResponse);
        }}
        onError={() => {
          console.log("Login Failed");
        }}
      />
    </GoogleOAuthProvider>
  );
};

export default Googlelogin;
