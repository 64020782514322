import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Skeleton,
  Dropdown,
  Menu,
  Button,
  Table,
  Space,
  Typography,
  Tag,
  Empty,
  Tooltip,
  Spin,
  Modal,
} from "antd";
import { Server } from "../../../App";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import toast from "react-hot-toast";
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  SearchOutlined,
  DownOutlined,
  FrownOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Tabs } from "antd";
const ManageJobs = () => {
  const navigate = useNavigate();
  const [activeJobs, setActiveJobs] = useState([]);
  const [inactiveJobs, setInactiveJobs] = useState([]);
  const [holdJobs, setHoldJobs] = useState([]);
  const [closedJobs, setClosedJobs] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [jobToDelete, setJobToDelete] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState({});
  const [loadingJobs, setLoadingJobs] = useState({});
  let userId = localStorage.getItem("UserId");
  const onChange = (key) => {
    console.log(key);
  };
  const handleDropdownVisibleChange = (visible, jobId) => {
    setDropdownVisible((prev) => ({ ...prev, [jobId]: visible }));
  };
  const keyItems = [
    {
      key: "1",
      label: "Active",
      children: "Content of Tab Pane 1",
    },
    {
      key: "2",
      label: "Inactive",
      children: "Content of Tab Pane 2",
    },
    {
      key: "3",
      label: "Hold",
      children: "Content of Tab Pane 3",
    },
    {
      key: "4",
      label: "Closed",
      children: "Content of Tab Pane 4",
    },
  ];
  const getJobs = async () => {
    try {
      setLoader(true);
      const { data } = await axios.get(
        `${Server}/api/v1/employer/jobs/get/${userId}`
      );
      const sortJobs = data.jobs?.sort(
        (a, b) => new Date(b.time) - new Date(a.time)
      );
      console.log(sortJobs);

      setJobs(sortJobs);
      setLoader(false);
      // console.log(data.jobs);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    getJobs();
  }, []);
  useEffect(() => {
    const filterJobs = () => {
      const active = jobs.filter((job) => job.status === "Active");
      const inactive = jobs.filter((job) => job.status === "Inactive");
      const hold = jobs.filter((job) => job.status === "Hold");
      const closed = jobs.filter((job) => job.status === "Closed");

      setActiveJobs(active);
      setInactiveJobs(inactive);
      setHoldJobs(hold);
      setClosedJobs(closed);
    };

    filterJobs();
  }, [jobs]);
  let profileCompleted = localStorage.getItem("ProfileCompleted");
  let role = localStorage.getItem("Role");

  useEffect(() => {
    if (profileCompleted === "false") {
      if (role === "Candidate") {
        navigate("/my-profile");
      } else {
        navigate("/company-profile");
      }
    }
  }, [profileCompleted, role, navigate]);

  useEffect(() => {
    // Scroll to the top when navigation occurs
    window.scrollTo(0, 0);
  }, []);

  const deleteShortlisted = async (Id) => {
    try {
      await axios.delete(
        `${Server}/api/v1/employer/jobs/delete/${userId}/${Id}`
      );
      toast.success("Deleted Successfully");
      setJobs((prevData) => prevData.filter((item) => item.jobId !== Id));
    } catch (error) {
      console.error(error);
    }
  };

  const statusChange = async (status, jobId, jobTitle, email) => {
    setLoadingJobs((prev) => ({ ...prev, [jobId]: true }));
    try {
      const statusValues = { status, jobTitle, email };
      await axios.post(
        `${Server}/api/v1/employer/jobs/status/${userId}/${jobId}`,
        statusValues
      );
      toast.success("Job Status Changed Successfully!");
      setDropdownVisible((prev) => ({ ...prev, [jobId]: false }));
      getJobs();
      setLoadingJobs((prev) => ({ ...prev, [jobId]: false }));
    } catch (error) {
      console.error(error);
    }
  };

  const items = [
    { label: "Active", key: "0" },
    { label: "Inactive", key: "1" },
    { label: "Closed", key: "2" },
    { label: "Hold", key: "3" },
  ];
  const showModal = (jobId) => {
    setJobToDelete(jobId);
    setIsModalVisible(true);
  };
  const handleOk = async () => {
    try {
      const { data } = await axios.delete(
        `${Server}/api/v1/employer/jobs/delete/${userId}/${jobToDelete}`
      );
      console.log(data);
      toast.success("Deleted Successfully");
      setJobs((prevData) =>
        prevData.filter((item) => item.jobId !== jobToDelete)
      );
      setIsModalVisible(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const renderMenuItems = (record) => {
    return items.map((item) => {
      const disabled = item.label === "Active" && record.status === "Active"; // Disable if status is Active
      return (
        <Menu.Item
          key={item.key}
          onClick={() => statusChange(item.label, record.jobId)}
          disabled={loadingJobs[record.jobId] || disabled} // Disable if loading or status is Active
        >
          {loadingJobs[record.jobId] && (
            <LoadingOutlined style={{ marginRight: 8 }} />
          )}{" "}
          {/* Loader */}
          {item.label}
        </Menu.Item>
      );
    });
  };
  const columns = [
    {
      title: "Title",
      dataIndex: "JobTitle",
      key: "title",
      render: (text, record) => (
        <Space direction="vertical">
          <Typography.Title level={5}>{text}</Typography.Title>
          <Typography.Text type="secondary">
            <i className="icon flaticon-map-locator"></i> {record.City},{" "}
            {record.Country}
          </Typography.Text>
        </Space>
      ),
    },
    {
      title: "Applications",
      dataIndex: "applicants",
      key: "applications",
      render: (text, record) => (
        <Link to="/applicants" state={{ jobTitle: record.JobTitle }}>
          <Tag color="blue">
            {record.applicants?.filter((app) => app.applicationStatus === "pending")
              .length || 0}{" "}
            Applied
          </Tag>
        </Link>
      ),
    },
    {
      title: "Created & Expired",
      dataIndex: "postedTime",
      key: "createdExpired",
      render: (text, record) => (
        <Space direction="vertical">
          {console.log(record?.ApplicationDeadlineDate)}
          <Typography.Text>{record?.postedTime}</Typography.Text>
          <Typography.Text>
            {moment(record?.ApplicationDeadlineDate, "YYYY-MM-DD").format(
              "MMMM Do YYYY"
            )}
          </Typography.Text>
        </Space>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <Tag
          color={
            text === "Active"
              ? "green"
              : text === "Inactive"
              ? "volcano"
              : text === "Closed"
              ? "red"
              : "gold"
          }
        >
          {text}
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space direction="vertical">
          <Space size="middle">
            {/* Tooltip for the Eye Icon */}
            <Tooltip title="View" placement="top">
              <Button
                icon={<EyeOutlined />}
                onClick={() =>
                  navigate(`/job-detail/${userId}/${record.jobId}`)
                }
              />
            </Tooltip>

            {/* Dropdown with Tooltip */}
            <Tooltip title="Options">
              <Dropdown
                overlay={
                  <Menu>
                    {items.map((item) => (
                      <Menu.Item
                        key={item.key}
                        onClick={() =>
                          statusChange(
                            item.label,
                            record.jobId,
                            record.JobTitle,
                            record.EmailAddress
                          )
                        }
                      >
                        {item.label}
                      </Menu.Item>
                    ))}
                  </Menu>
                }
                trigger={["click"]}
                visible={dropdownVisible[record.jobId]} // Control visibility based on state
                onVisibleChange={(visible) =>
                  handleDropdownVisibleChange(visible, record.jobId)
                } // Update visibility state
              >
                <Button icon={<DownOutlined />} />
              </Dropdown>
            </Tooltip>

            {/* Tooltip for the Delete Icon */}
            <Tooltip title="Delete">
              <Button
                icon={<DeleteOutlined />}
                onClick={() => showModal(record.jobId)}
              />
            </Tooltip>

            {/* Tooltip for the Edit Icon */}
            <Tooltip title="Edit">
              <Button
                icon={<EditOutlined />}
                onClick={() => navigate(`/edit-post/${record.jobId}`)}
              />
            </Tooltip>
          </Space>
        </Space>
      ),
    },
    {
      title: "",
      key: "resumeAI",
      render: (text, record) =>
        record.status === "Active" ? ( // Condition to check if status is "Active"
          <Tooltip title="Resume Search with AI" placement="top">
            <div
              className="loader"
              onClick={() =>
                navigate("/aiSearch", { state: { jobdetails: record } })
              }
            >
              <span className="loader__inner"></span>
              <span className="loader__inner"></span>
              <span className="loader__text">
                <SearchOutlined />
              </span>
            </div>
          </Tooltip>
        ) : null,
    },
  ];
  const renderJobsTable = (jobList) => {
    // if (jobList.length === 0) {
    //   return <Empty description="No Jobs Available" />;
    // }

    return (
      <div className="table-outer">
        <Table
          columns={columns}
          className="manage-tables"
          dataSource={jobList}
          rowKey={(record) => record.jobId}
          pagination={false}
          loading={loader}
          locale={{
            emptyText: <Empty description="No Jobs Available" />,
          }}
        />
      </div>
    );
  };

  return (
    <div>
      <section className="user-dashboard">
        <div className="dashboard-outer">
          <div className="upper-title-box">
            <Typography.Title level={2}>Manage Jobs</Typography.Title>
            <Typography.Text>Ready to jump back in?</Typography.Text>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="ls-widget">
                <div className="tabs-box">
                  <div className="widget-title">
                    <Typography.Title level={4}>
                      My Job Listings
                    </Typography.Title>
                    <div className="chosen-outer">
                      <select className="chosen-select">
                        <option>Last 6 Months</option>
                        <option>Last 12 Months</option>
                        <option>Last 16 Months</option>
                        <option>Last 24 Months</option>
                        <option>Last 5 year</option>
                      </select>
                    </div>
                  </div>
                  <div className="widget-content">
                    {/* <Tabs
                      defaultActiveKey="1"
                      items={keyItems}
                      onChange={onChange}
                    />*/}
                    <Tabs defaultActiveKey="1">
                      <Tabs.TabPane tab="Active" key="1">
                        {renderJobsTable(activeJobs)}
                      </Tabs.TabPane>
                      <Tabs.TabPane tab="Inactive" key="2">
                        {renderJobsTable(inactiveJobs)}
                      </Tabs.TabPane>
                      <Tabs.TabPane tab="Hold" key="3">
                        {renderJobsTable(holdJobs)}
                      </Tabs.TabPane>
                      <Tabs.TabPane tab="Closed" key="4">
                        {renderJobsTable(closedJobs)}
                      </Tabs.TabPane>
                    </Tabs>
                    {/*<div className="table-outer">
                      <Table
                        columns={columns}
                        dataSource={jobs}
                        rowKey={(record) => record.jobId}
                        pagination={false}
                        loading={loader}
                        locale={{
                          emptyText: <Empty description="No Jobs Available" />,
                        }}
                      />
                    </div>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="copyright-text">
        <Typography.Text>
          © 2024 Skill Stream. All Right Reserved.
        </Typography.Text>
        <Modal
          title="Delete Job"
          open={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <p>Do you want to delete this job?</p>
        </Modal>
      </div>
    </div>
  );
};

export default ManageJobs;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { Skeleton, Dropdown, Menu, Button, Card, Typography, Tag, Space } from "antd";
// import { Server } from "../../../App";
// import { useNavigate } from "react-router-dom";
// import moment from "moment";
// import toast from "react-hot-toast";
// import { EditOutlined, DeleteOutlined, EyeOutlined, SearchOutlined, DownOutlined } from '@ant-design/icons';

// const ManageJobs = () => {
//   const navigate = useNavigate();
//   const [jobs, setJobs] = useState([]);
//   let userId = localStorage.getItem("UserId");

//   const getJobs = async () => {
//     try {
//       const { data } = await axios.get(`${Server}/api/v1/employer/jobs/get/${userId}`);
//       setJobs(data.jobs);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   useEffect(() => {
//     getJobs();
//   }, []);

//   let profileCompleted = localStorage.getItem("ProfileCompleted");
//   let role = localStorage.getItem("Role");

//   useEffect(() => {
//     if (profileCompleted === "false") {
//       if (role === "Candidate") {
//         navigate("/my-profile");
//       } else {
//         navigate("/company-profile");
//       }
//     }
//   }, [profileCompleted, role, navigate]);

//   useEffect(() => {
//     // Scroll to the top when navigation occurs
//     window.scrollTo(0, 0);
//   }, []);

//   const deleteShortlisted = async (Id) => {
//     try {
//       await axios.delete(`${Server}/api/v1/employer/jobs/delete/${userId}/${Id}`);
//       toast.success("Deleted Successfully");
//       setJobs((prevData) => prevData.filter((item) => item.jobId !== Id));
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const statusChange = async (status, jobId) => {
//     try {
//       const statusValues = { status };
//       await axios.post(`${Server}/api/v1/employer/jobs/status/${userId}/${jobId}`, statusValues);
//       toast.success("Job Status Changed Successfully!");
//       getJobs();
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const items = [
//     { label: "Active", key: "0" },
//     { label: "Inactive", key: "1" },
//     { label: "Closed", key: "2" },
//     { label: "Hold", key: "3" },
//   ];

//   const containerStyle = {
//     padding: '20px',
//   };

//   const dashboardOuterStyle = {
//     backgroundColor: '#f5f5f5',
//     borderRadius: '8px',
//     padding: '20px',
//   };

//   const upperTitleBoxStyle = {
//     textAlign: 'center',
//     marginBottom: '20px',
//   };

//   const cardsContainerStyle = {
//     display: 'flex',
//     flexWrap: 'wrap',
//     gap: '20px',
//     justifyContent: 'center',
//   };

//   const jobCardStyle = {
//     width: '300px',
//     borderRadius: '8px',
//     transition: 'transform 0.3s ease',
//     boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
//   };

//   const jobCardHoverStyle = {
//     transform: 'scale(1.05)',
//   };

//   const cardActionsStyle = {
//     marginTop: '20px',
//   };

//   const chosenOuterStyle = {
//     display: 'flex',
//     justifyContent: 'flex-end',
//     marginBottom: '20px',
//   };

//   const chosenSelectStyle = {
//     padding: '8px',
//     borderRadius: '4px',
//     border: '1px solid #ccc',
//   };

//   const copyrightTextStyle = {
//     textAlign: 'center',
//     marginTop: '20px',
//   };

//   return (
//     <div style={containerStyle}>
//       <section className="user-dashboard" style={dashboardOuterStyle}>
//         <div className="dashboard-outer">
//           <div className="upper-title-box" style={upperTitleBoxStyle}>
//             <Typography.Title level={2}>Manage Jobs</Typography.Title>
//             <Typography.Text>Ready to jump back in?</Typography.Text>
//           </div>
//           <div className="row">
//             <div className="col-lg-12">
//               <div className="ls-widget">
//                 <div className="tabs-box">
//                   <div className="widget-title">
//                     <Typography.Title level={4}>My Job Listings</Typography.Title>
//                     <div className="chosen-outer" style={chosenOuterStyle}>
//                       <select className="chosen-select" style={chosenSelectStyle}>
//                         <option>Last 6 Months</option>
//                         <option>Last 12 Months</option>
//                         <option>Last 16 Months</option>
//                         <option>Last 24 Months</option>
//                         <option>Last 5 years</option>
//                       </select>
//                     </div>
//                   </div>
//                   <div className="widget-content">
//                     <div className="cards-container" style={cardsContainerStyle}>
//                       {jobs.length === 0 ? (
//                         <Skeleton active />
//                       ) : (
//                         jobs.map((job) => (
//                           <Card
//                             className="job-card"
//                             key={job.jobId}
//                             hoverable
//                             style={jobCardStyle}
//                             bodyStyle={jobCardStyle}
//                             headStyle={jobCardHoverStyle}
//                           >
//                             <Typography.Title level={4}>{job.JobTitle}</Typography.Title>
//                             <Typography.Text>
//                               <i className="icon flaticon-map-locator"></i> {job.City}, {job.Country}
//                             </Typography.Text>
//                             <Space direction="vertical" style={{ marginTop: 10 }}>
//                               <Tag color="blue">{job.applicants?.length || 0} Applied</Tag>
//                               <Typography.Text>Posted: {moment(job.postedTime).format('MMMM Do YYYY')}</Typography.Text>
//                               <Typography.Text>Deadline: {moment(job.ApplicationDeadlineDate).format('MMMM Do YYYY')}</Typography.Text>
//                               <Tag color={job.status === "Active" ? "green" : job.status === "Inactive" ? "volcano" : job.status === "Closed" ? "red" : "gold"}>
//                                 {job.status}
//                               </Tag>
//                             </Space>
//                             <Space direction="vertical" style={cardActionsStyle}>
//                               <Button
//                                 icon={<EyeOutlined />}
//                                 onClick={() => navigate(`/job-detail/${userId}/${job.jobId}`)}
//                               >
//                                 View
//                               </Button>
//                               <Dropdown
//                                 overlay={
//                                   <Menu>
//                                     {items.map((item) => (
//                                       <Menu.Item
//                                         key={item.key}
//                                         onClick={() => statusChange(item.label, job.jobId)}
//                                       >
//                                         {item.label}
//                                       </Menu.Item>
//                                     ))}
//                                   </Menu>
//                                 }
//                                 trigger={['click']}
//                               >
//                                 <Button icon={<DownOutlined />}>Change Status</Button>
//                               </Dropdown>
//                               <Button
//                                 icon={<DeleteOutlined />}
//                                 onClick={() => deleteShortlisted(job.jobId)}
//                               >
//                                 Delete
//                               </Button>
//                               <Button
//                                 icon={<EditOutlined />}
//                                 onClick={() => navigate(`/edit-post/${job.jobId}`)}
//                               >
//                                 Edit
//                               </Button>
//                               <Button
//                                 type="primary"
//                                 icon={<SearchOutlined />}
//                                 onClick={() => navigate("/search-resume")}
//                                 style={{ marginTop: 10 }}
//                               >
//                                 Search Resume with AI
//                               </Button>
//                             </Space>
//                           </Card>
//                         ))
//                       )}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//       <div className="copyright-text" style={copyrightTextStyle}>
//         <Typography.Text>© 2024 Skill Stream. All Right Reserved.</Typography.Text>
//       </div>
//     </div>
//   );
// };

// export default ManageJobs;
// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { Skeleton, Modal } from "antd";
// import { Server } from "../../../App";
// import { useNavigate, useParams } from "react-router-dom";
// import moment from "moment";
// import toast, { Toaster } from "react-hot-toast";
// import { textDecoration } from "@chakra-ui/react";
// import { Dropdown, Space, Menu, Typography } from "antd";

// const ManageJobs = () => {
//   const navigate = useNavigate();
//   const [jobs, setJobs] = useState([]);
//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const [jobToDelete, setJobToDelete] = useState(null);
//   let userId = localStorage.getItem("UserId");

//   const getJobs = async () => {
//     try {
//       let userId = localStorage.getItem("UserId");
//       const { data } = await axios.get(
//         `${Server}/api/v1/employer/jobs/get/${userId}`
//       );

//       setJobs(data.jobs);
//       console.log(data.jobs);
//     } catch (error) {}
//   };

//   useEffect(() => {
//     getJobs();
//   }, []);

//   let profileCompleted = localStorage.getItem("ProfileCompleted");
//   let role = localStorage.getItem("Role");
//   useEffect(() => {
//     if (profileCompleted === "false") {
//       console.log(typeof profileCompleted);
//       if (role === "Candidate") {
//         navigate("/my-profile");
//       } else {
//         navigate("/company-profile");
//       }
//     }
//   }, []);

//   useEffect(() => {
//     // Scroll to the top when navigation occurs
//     window.scrollTo(0, 0);
//   }, []);

//   const showModal = (jobId) => {
//     setJobToDelete(jobId);
//     setIsModalVisible(true);
//   };

//   const handleOk = async () => {
//     try {
//       const { data } = await axios.delete(
//         `${Server}/api/v1/employer/jobs/delete/${userId}/${jobToDelete}`
//       );
//       console.log(data);
//       toast.success("Deleted Successfully");
//       setJobs((prevData) => prevData.filter((item) => item.jobId !== jobToDelete));
//       setIsModalVisible(false);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const handleCancel = () => {
//     setIsModalVisible(false);
//   };

//   const statusChange = async (status, jobId) => {
//     try {
//       console.log(status, jobId);

//       let statusValues = {
//         status,
//       };
//       const { data } = await axios.post(
//         `${Server}/api/v1/employer/jobs/status/${userId}/${jobId}`,
//         statusValues
//       );
//       console.log(data);
//       toast.success("Job Status Changed Successfully!");
//       getJobs();
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const items = [
//     {
//       label: <a>Active</a>,
//       key: "0",
//     },
//     {
//       label: <a>Inactive</a>,
//       key: "1",
//     },
//     {
//       label: <a>Closed</a>,
//       key: "2",
//     },
//     {
//       label: <a>Hold</a>,
//       key: "3",
//     },
//   ];

//   return (
//     <div>
//       <section className="user-dashboard">
//         <div className="dashboard-outer">
//           <div className="upper-title-box">
//             <h3>Manage Jobs</h3>
//             <div className="text">Ready to jump back in?</div>
//           </div>

//           <div className="row">
//             <div className="col-lg-12">
//               <div className="ls-widget">
//                 <div className="tabs-box">
//                   <div className="widget-title">
//                     <h4>My Job Listings</h4>

//                     <div className="chosen-outer">
//                       <select className="chosen-select">
//                         <option>Last 6 Months</option>
//                         <option>Last 12 Months</option>
//                         <option>Last 16 Months</option>
//                         <option>Last 24 Months</option>
//                         <option>Last 5 year</option>
//                       </select>
//                     </div>
//                   </div>

//                   <div className="widget-content">
//                     <div className="table-outer">
//                       {jobs?.length === 0 ? (
//                         <p className="text-center">No Jobs Posted</p>
//                       ) : (
//                         <table className="default-table manage-job-table">
//                           <thead>
//                             <tr>
//                               <th>Title</th>
//                               <th>Applications</th>
//                               <th>Created & Expired</th>
//                               <th>Status</th>
//                               <th>Action</th>
//                             </tr>
//                           </thead>
//                           {jobs?.map((e, idx) => (
//                             <tbody key={idx}>
//                               <tr>
//                                 <td>
//                                   <h6>{e.JobTitle}</h6>
//                                   <span className="info">
//                                     <i className="icon flaticon-map-locator"></i>{" "}
//                                     {e.City}, {e.Country}
//                                   </span>
//                                 </td>
//                                 {e.applicants ? (
//                                   <td className="applied">
//                                     <a onClick={() => navigate("/applicants")}>
//                                       {e.applicants?.length} Applied
//                                     </a>
//                                   </td>
//                                 ) : (
//                                   <td
//                                     className="applied"
//                                     style={{
//                                       textDecoration: "none !important ",
//                                     }}
//                                   >
//                                     <p>0 Applied</p>
//                                   </td>
//                                 )}

//                                 <td>
//                                   {e.postedTime} <br />
//                                   {e.ApplicationDeadlineDate}
//                                 </td>
//                                 <td className="status">{e.status}</td>
//                                 <td>
//                                   <div className="option-box">
//                                     <ul className="option-list">
//                                       <li>
//                                         <button
//                                           onClick={() =>
//                                             navigate(
//                                               `/job-detail/${userId}/${e.jobId}`
//                                             )
//                                           }
//                                           data-text="View Job"
//                                         >
//                                           <span className="la la-eye"></span>
//                                         </button>
//                                       </li>
//                                       <li>
//                                         <button data-text="Change Status">
//                                           <Dropdown
//                                             overlay={
//                                               <Menu>
//                                                 {items?.map((item) => (
//                                                   <Menu.Item
//                                                     key={item.key}
//                                                     onClick={() =>
//                                                       statusChange(
//                                                         item.label.props
//                                                           .children,
//                                                         e.jobId
//                                                       )
//                                                     }
//                                                   >
//                                                     {item.label}
//                                                   </Menu.Item>
//                                                 ))}
//                                               </Menu>
//                                             }
//                                             trigger={["click"]}
//                                           >
//                                             <a
//                                               onClick={(e) =>
//                                                 e.preventDefault()
//                                               }
//                                             >
//                                               <span className="la la-pencil"></span>
//                                             </a>
//                                           </Dropdown>
//                                         </button>
//                                       </li>
//                                       <li>
//                                         <button
//                                           data-text="Delete Job"
//                                           onClick={() => showModal(e.jobId)}
//                                         >
//                                           <span className="la la-trash"></span>
//                                         </button>
//                                       </li>
//                                       <li>
//                                         <button
//                                           data-text="Edit Job"
//                                           onClick={() =>
//                                             navigate(`/edit-post/${e.jobId}`)
//                                           }
//                                         >
//                                           <span className="la la-edit"></span>
//                                         </button>
//                                       </li>
//                                     </ul>
//                                   </div>
//                                 </td>
//                               </tr>
//                             </tbody>
//                           ))}
//                         </table>
//                       )}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>

//       <div className="copyright-text">
//         <p>© 2024 Skill Stream. All Right Reserved.</p>
//       </div>

//       <Modal
//         title="Delete Job"
//         visible={isModalVisible}
//         onOk={handleOk}
//         onCancel={handleCancel}
//       >
//         <p>Do you want to delete this job?</p>
//       </Modal>
//     </div>
//   );
// };

// export default ManageJobs;
